import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-bootstrap';

import {
  ContactContainer,
  ContactLabel,
  ContactValue,
  Description,
  Title,
  Content
} from './ContactUs.styled';
import { ContactUsForm } from '../../Components';
import { servicesRequest } from '../../Utils';
import { CONTACT_URL } from '../../Utils/serviceUrl.utils';
import { getHtmlContent } from '../../Utils/general.utils';

const ContactUsScreen = () => {
  const [contact, setContact] = React.useState(null);
  const [branch, setBranch] = React.useState(null);
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const { data } = await authorizedRequest.get(CONTACT_URL);
      setContact(data.data);
      setBranch(JSON.parse(data.data.branch));
    } catch (error) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const updatedBranchData = branch?.map(item => {
    const updatedMaps = item?.maps.replace('width="600"', 'width="100%"');
    return { ...item, maps: updatedMaps };
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Have A Enquiries? Contact Us" />
      </Helmet>
      <Row>
        <Col md={6} lg={6} xs={12}>
          <ContactContainer>
            <Title>{t('contact-us')}</Title>
            {contact && (
              <Description
                dangerouslySetInnerHTML={getHtmlContent(contact, i18n.language)}
              />
            )}
            <Content
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: contact?.address_map.replace(
                  'width="600"',
                  'width="100%"'
                )
              }}
            />
          </ContactContainer>
        </Col>
        <Col md={6} lg={6} xs={12}>
          <ContactContainer>
            {updatedBranchData?.map((item, index) => (
              <div key={index} className="mb-5">
                <Title>{item?.storeName}</Title>
                <ContactLabel>{t('contact')}</ContactLabel>
                <ContactValue>{item?.storeContact}</ContactValue>
                <ContactLabel>{t('address')}</ContactLabel>
                <ContactValue>{item?.address}</ContactValue>
                <ContactLabel>{t('working-hour')}</ContactLabel>
                <ContactValue>{item?.workHour}</ContactValue>
              </div>
            ))}
          </ContactContainer>
        </Col>

        <Col md={12} lg={12} xs={12}>
          <ContactContainer>
            {updatedBranchData?.map((item, index) => (
              <div key={index} className="mb-5">
                <Content
                  className="mt-4"
                  dangerouslySetInnerHTML={{ __html: item?.maps }}
                />
              </div>
            ))}
          </ContactContainer>
        </Col>
      </Row>
      <ContactUsForm />
    </React.Fragment>
  );
};

export default ContactUsScreen;
