import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  Description,
  RightSide,
  SubSection,
  SectionTitle,
  View,
  Spacer
} from './Home.styled';
import { Icon } from '../../Components';
import { StyledLink } from '../../Assets/GlobalStyled';
import ProductComponent from './Components/Product.component';
import EventComponent from './Components/Event.component';
import { ROUTE } from '../../Constants';
import { checkValidURLImage } from '../../Utils/general.utils';
import { servicesRequest } from '../../Utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';

const HomeContainer = () => {
  const [bannerURL, setBannerURL] = React.useState([]);
  const { t } = useTranslation();

  const fetchBanner = async () => {
    try {
      const request = await servicesRequest();
      let {
        data: {
          data: { thumbnail }
        }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'HOME_BANNER'
        }
      });

      thumbnail = JSON.parse(thumbnail);
      setBannerURL(thumbnail);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetchBanner().catch(console.error);
  }, []);

  const handleRedirectUrl = item => {
    if (
      item?.redirect_url &&
      item?.redirect_url !== '' &&
      item?.redirect_url !== '#'
    ) {
      window.open(item?.redirect_url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="mb-4">
      <Carousel
        arrows
        autoplay
        dots={true}
        className="align-items-center mb-3 cursor">
        {bannerURL?.map((item, index) => (
          // <img key={index} alt={index} src={checkValidURLImage(item?.uri)} />
          <img
            key={index}
            alt={index}
            src={checkValidURLImage(item?.uri)}
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectUrl(item)}
          />
        ))}
      </Carousel>

      <Row className="justify-content-center align-items-center mb-1 mt-3">
        <Col md={4}>
          <Description>{t('home-description')}</Description>
        </Col>
      </Row>
      <Row className="px-3 mt-5">
        <Col md={4}></Col>
        <Col md={4}>
          <SectionTitle>{t('new-arrivals')}</SectionTitle>
        </Col>
        <Col md={4} className="text-right">
          <SubSection>
            <StyledLink to={'../' + ROUTE.product} className="py-4">
              <RightSide>
                <View>{t('view')}</View>
                <Icon
                  IconShow={IoIosArrowForward}
                  style={{ size: 20, color: '#cda257' }}
                />
              </RightSide>
            </StyledLink>
          </SubSection>
        </Col>
      </Row>

      <Spacer>
        <ProductComponent />
      </Spacer>

      <Row className="px-3 mt-5">
        <Col md={4}></Col>
        <Col md={4}>
          <SectionTitle>{t('wine-tasting-diner')}</SectionTitle>
        </Col>
        <Col md={4} className="text-right">
          <SubSection>
            <StyledLink to={'../' + ROUTE.event} className="py-4">
              <RightSide>
                <View>{t('view')}</View>
                <Icon
                  IconShow={IoIosArrowForward}
                  style={{ size: 20, color: '#cda257' }}
                />
              </RightSide>
            </StyledLink>
          </SubSection>
        </Col>
      </Row>
      <Spacer>
        <EventComponent />
      </Spacer>
    </div>
  );
};

export default HomeContainer;
