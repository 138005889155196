import React from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';

import { BlogList, Pagination } from '../../Components';
import {
  Container,
  ContentContainer,
  FirstTitle,
  SearchButton,
  SearchContainer,
  SearchInput,
  SecondTitle
} from './Blog.styled';
import { Image } from '../Home/Home.styled';
import {
  DropdownBtn,
  FilterFlex,
  FilterLabel,
  FilterTopContainer,
  FilterTopLeftContainer,
  TopFilter,
  TopIconContainer,
  TotalItemContainer
} from '../Product/Product.styled';
import { servicesRequest } from '../../Utils';
import { BLOG_URL, CONTENT_URL } from '../../Utils/serviceUrl.utils';
import { checkValidURLImage } from '../../Utils/general.utils';

const BlogScreen = () => {
  const [blogs, setBlogs] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [pageSize, setPaeSize] = React.useState(6);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '1'
  });
  const [bannerURL, setBannerURL] = React.useState([]);
  const { t } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(BLOG_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      const paginationData = {
        itemperpage: pageSize,
        page: data.currentPage,
        total_item: data.totalItems,
        total_page: data.totalPages
      };

      setPagination(paginationData);
      setTotalItems(data.totalItems);
      setBlogs(data.contents);
    } catch (e) {}
  };

  const fetchBanner = async () => {
    try {
      const request = await servicesRequest();
      let {
        data: {
          data: { thumbnail }
        }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'BLOG_BANNER'
        }
      });

      thumbnail = JSON.parse(thumbnail);

      setBannerURL(thumbnail);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetchBanner().catch(console.error);
  }, []);

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages, totalItems, pageSize]);

  const handleRedirectUrl = item => {
    if (
      item?.redirect_url &&
      item?.redirect_url !== '' &&
      item?.redirect_url !== '#'
    ) {
      window.open(item?.redirect_url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Container>
      <Carousel autoplay dots={false} className="pt-4">
        {bannerURL?.map((item, index) => (
          <Image
            key={index}
            src={checkValidURLImage(item?.uri)}
            onClick={() => handleRedirectUrl(item)}
          />
        ))}
      </Carousel>
      <Row>
        <Col md={12}>
          <FirstTitle>{t('tasting-blog')}</FirstTitle>
          <SecondTitle>{t('stories-private-tasting')}</SecondTitle>
        </Col>
        <Col>
          <SearchContainer className="p-4">
            <SearchInput
              onChange={e => setSearch(e.target.value)}
              placeholder={t('enter-your-keyword')}
            />
            <SearchButton
              onClick={() => {
                fetching().catch(console.error);
              }}>
              {t('search')}
            </SearchButton>
          </SearchContainer>
        </Col>
      </Row>
      <Row>
        <TopFilter className="mb-2 px-5">
          <FilterTopContainer>
            <Col className="pt-2">
              <FilterLabel>
                <TopIconContainer className="product-tabel">
                  <TotalItemContainer>
                    {t('showing')}{' '}
                    {pagination.page * pagination.itemperpage + 1} -{' '}
                    {pagination.page * pagination.itemperpage +
                      pagination.itemperpage}{' '}
                    of {pagination.total_item} {t('items')}
                  </TotalItemContainer>
                </TopIconContainer>
              </FilterLabel>
            </Col>
            <FilterTopLeftContainer>
              <FilterFlex>
                <FilterLabel>{t('show')}:</FilterLabel>
                <DropdownBtn title={pageSize} variant="Secondary">
                  <Dropdown.Item onClick={() => setPaeSize(6)}>6</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPaeSize(24)}>
                    24
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPaeSize(36)}>
                    36
                  </Dropdown.Item>
                </DropdownBtn>
              </FilterFlex>
              <FilterFlex className="px-3">
                <FilterLabel>{t('position')}</FilterLabel>
                <DropdownBtn title="Position" variant="Secondary">
                  <Dropdown.Item href="#">1</Dropdown.Item>
                  <Dropdown.Item href="#">2</Dropdown.Item>
                  <Dropdown.Item href="#">3</Dropdown.Item>
                </DropdownBtn>
              </FilterFlex>
            </FilterTopLeftContainer>
          </FilterTopContainer>
        </TopFilter>
      </Row>
      <ContentContainer>
        <BlogList data={blogs} />
        <Pagination
          data={{ total_item: totalItems, itemperpage: pageSize }}
          onChangePage={page => {
            setPages(page - 1);
          }}
        />
      </ContentContainer>
    </Container>
  );
};

export default BlogScreen;
